import React from 'react';
import cls from 'classnames';

import { Box } from 'wui/Box';

import { ImageRatio } from 'settings/consts';

import styles from './AspectRatio.scss';

interface AspectRatioProps extends React.ComponentProps<typeof Box> {
  aspectRatio: ImageRatio;
}

export function AspectRatio({
  className,
  children,
  aspectRatio,
  ...rest
}: AspectRatioProps) {
  return (
    <Box
      className={cls(styles.root, className, {
        [styles.cinema]: aspectRatio === ImageRatio.rectangle,
        [styles.square]: aspectRatio === ImageRatio.square,
      })}
      {...rest}
    >
      <div className={styles.child}>{children}</div>
    </Box>
  );
}

AspectRatio.displayName = 'wui/AspectRatio';
