import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';

import settingsParams from 'Groups/settingsParams';

import { AspectRatio } from 'wui/AspectRatio';
import { Card } from 'wui/Card';
import { CardActions } from 'wui/CardActions';
import { CardContent } from 'wui/CardContent';
import { Skeleton } from 'wui/Skeleton';
import { Show } from 'wui/Show';
import { Button } from 'wui/Button';

import styles from './GroupGridItem.scss';

export function GroupGridItemSkeleton() {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const imageRatio = settings.get(settingsParams.imageRatio);

  return (
    <Card
      gap="SP0"
      padding="SP0"
      sideBorders={!isMobile}
      className={styles.root}
    >
      <Show if={settings.get(settingsParams.showImage)}>
        <AspectRatio aspectRatio={imageRatio}>
          <Skeleton variant="rect" width="100%" height="100%" />
        </AspectRatio>
      </Show>
      <CardContent disableSideGutters className={styles.content}>
        <Skeleton
          className={cls(styles.title, { [styles.mobile]: isMobile })}
          variant="text"
        />
        <Skeleton
          className={cls(styles.info, { [styles.mobile]: isMobile })}
          variant="text"
          width="50%"
        />
      </CardContent>
      <Show if={settings.get(groupsListWidgetSettingsParams.showButton)}>
        <CardActions
          paddingTop="SP1"
          disableSideGutters
          className={cls(styles.actions, styles.hidden)}
        >
          <Button>&nbsp;</Button>
        </CardActions>
      </Show>
    </Card>
  );
}

GroupGridItemSkeleton.displayName = 'GroupGridItemSkeleton';
