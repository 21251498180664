import React from 'react';
import cls from 'classnames';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Accordion as TPAAccordion } from 'wix-ui-tpa/cssVars';

import { Paper } from '../Paper';

import { ACCORDION_ROOT } from './dataHooks';

import styles from './Accordion.scss';

interface IAccordionProps extends React.ComponentProps<typeof TPAAccordion> {}

export function Accordion(props: IAccordionProps) {
  const { className, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <Paper sideBorders={!isMobile}>
      <TPAAccordion
        className={cls(styles.root, className)}
        data-hook={ACCORDION_ROOT}
        {...rest}
      />
    </Paper>
  );
}

Accordion.displayName = 'wui/Accordion';
