import React from 'react';
import cls from 'classnames';
import { throttle } from 'lodash';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';

import { Groups } from '../Groups';

import styles from './Sidebar.scss';

export function Sidebar() {
  const { isSSR, isEditor } = useEnvironment();

  const container$ = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handler = throttle(handleScroll, 100);

    if (isSSR || isEditor) {
      return;
    }

    handleScroll();
    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };

    function handleScroll() {
      const clientRect = container$.current?.getBoundingClientRect();
      const top = clientRect?.top || 0;

      container$.current?.style.setProperty('--sidebarOffsetTop', top + 'px');
    }
  }, []);

  return (
    <Card
      ref={container$}
      className={cls(styles.root, {
        [styles.editor]: isEditor,
      })}
    >
      <CardContent className={styles.content}>
        <Groups wired className={styles.groups} />
      </CardContent>
    </Card>
  );
}

Sidebar.displayName = 'Sidebar';
