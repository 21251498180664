import React from 'react';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { Alignment } from 'settings/consts';
import { feedSettingsParams, feedStylesParams } from 'settings/feed';
import { groupsSettingsParams, groupsStylesParams } from 'settings/groups';

import { TextButton } from 'wui/TextButton';
import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Typography } from 'wui/Typography';

import { CentralFeed } from '../../CentralFeed';
import { PendingGroups } from '../../PendingGroups';

import { Sidebar } from './Sidebar';
import { GroupsDialog } from './GroupsDialog';

import { GROUP_LIST_LINK_DATA_HOOK } from './dataHooks';

import classes from './SidebarLayout.scss';

export function SidebarLayout() {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const styles = useStyles();

  const showFeedTitle =
    settings.get(feedSettingsParams.showFeedTitle) || isMobile;
  const { htmlTag } = styles.get(feedStylesParams.feedTitleFont);

  const flip =
    settings.get(groupsSettingsParams.sideBarAlignment) === Alignment.left;

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      className={cls(classes.root, {
        [classes.flip]: flip,
        [classes.mobile]: isMobile,
      })}
    >
      <Show if={showFeedTitle}>
        <Box
          gap="SP2"
          align="space-between"
          className={classes.header}
          padding={isMobile ? 'SP0 SP5' : 'SP0'}
        >
          <Typography
            as={htmlTag}
            variant="h2-20"
            id="feed-title"
            className={classes.title}
            align={settings.get(groupsSettingsParams.headerAlignment)}
          >
            {settings.get(feedSettingsParams.feedTitle)}
          </Typography>
          <Show if={isMobile}>
            <TextButton
              data-hook={GROUP_LIST_LINK_DATA_HOOK}
              onClick={handleClick}
            >
              {t('groups-web.group-list.label')}
            </TextButton>
            <GroupsDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
          </Show>
        </Box>
      </Show>

      <Hide if={isMobile}>
        <div className={classes.sidebar}>
          <Sidebar />
        </div>
      </Hide>

      <Box
        direction="vertical"
        className={classes.main}
        gap={styles.get(groupsStylesParams.sideBarVerticalSpacing)}
      >
        <PendingGroups />
        <CentralFeed />
      </Box>
    </div>
  );

  function handleClick() {
    setIsOpen(true);
  }
}

SidebarLayout.displayName = 'SidebarLayout';
