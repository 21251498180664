import React from 'react';
import { useStyles } from '@wix/tpa-settings/react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';
import { Card } from 'wui/Card';

import { groupsStylesParams } from 'settings/groups';

import { Groups } from './Groups';
import { Header } from '../Header';
import { PendingGroups } from '../../PendingGroups';

const { groupCardListTopBottomSpacing, groupCardListSideSpacing } =
  groupsStylesParams;

export function ListLayout() {
  const styles = useStyles();
  const { isMobile } = useEnvironment();

  if (isMobile) {
    return (
      <Box gap="SP0" direction="vertical">
        <Header />
        <PendingGroups />
        <Groups />
      </Box>
    );
  }

  return (
    <Box gap="SP6" direction="vertical">
      <Header />

      <PendingGroups />

      <Card
        paddingTop={styles.get(groupCardListTopBottomSpacing)}
        paddingBottom={styles.get(groupCardListTopBottomSpacing)}
        paddingLeft={styles.get(groupCardListSideSpacing)}
        paddingRight={styles.get(groupCardListSideSpacing)}
      >
        <Groups />
      </Card>
    </Box>
  );
}

ListLayout.displayName = 'ListLayout';
