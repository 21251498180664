import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import {
  groupsRequestJoinAGroup,
  groupsSelectGroupFromList,
} from '@wix/bi-logger-groups/v2';

import { useSettings } from '@wix/tpa-settings/react';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';
import { groupsSettingsParams as settingsParams } from 'settings/groups/settings';
import { ImageRatio } from 'settings/consts';

import type { IGroup } from 'store/groups';

import { ListItem } from 'wui/ListItem';
import { ButtonSize } from 'wui/Button';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';
import { Show } from 'wui/Show';
import { ListItemIcon } from 'wui/ListItemIcon';
import { TextButton } from 'wui/TextButton';
import { Box } from 'wui/Box';

import { UISref } from 'router/react';
import { GroupInfo } from 'common/components/GroupInfo';
import { GroupMembershipButton } from 'common/components/GroupMembership';
import { GroupImage } from 'common/components/GroupImage';

import styles from './GroupListItem.scss';

interface IGroupListItemProps {
  wired?: boolean;
  group: IGroup;
}

export function SuggestedGroupListItem(props: IGroupListItemProps) {
  const { group, wired } = props;

  const { isMobile } = useEnvironment();
  const settings = useSettings();

  return (
    <ListItem
      disablePadding
      actionOnHover={!isMobile}
      className={cls({ [styles.wired]: props.wired })}
    >
      <Show if={settings.get(settingsParams.showImage)}>
        <ListItemIcon>
          <Box width={40} height={40}>
            <GroupImage
              width={40}
              height={40}
              resize="cover"
              image={group.coverImage?.image}
              aspectRatio={ImageRatio.square}
            />
          </Box>
        </ListItemIcon>
      </Show>
      <ListItemText
        title={
          <UISref
            state="group"
            params={{ slug: group.slug }}
            bi={groupsSelectGroupFromList({
              groupId: group.id as string,
              origin: 'new_layout_groups_sidebar_suggested_groups',
            })}
          >
            <TextButton as="a" variant="secondary">
              {group.name}
            </TextButton>
          </UISref>
        }
        subtitle={<GroupInfo wired={wired} groupId={group.id as string} />}
        subtitleProps={{ noWrap: true }}
        titleProps={{
          noWrap: true,
          className: cls(styles.title, { [styles.mobile]: isMobile }),
        }}
      />
      <Show if={settings.get(groupsListWidgetSettingsParams.showButton)}>
        <ListItemAction>
          <GroupMembershipButton
            upgrade={!isMobile}
            size={isMobile ? ButtonSize.tiny : undefined}
            groupId={group.id as string}
            bi={groupsRequestJoinAGroup({
              group_id: group.id as string,
              origin: 'new_layout_groups_sidebar_suggested_groups',
            })}
          />
        </ListItemAction>
      </Show>
    </ListItem>
  );
}

SuggestedGroupListItem.displayName = 'SuggestedGroupListItem';
