import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';
import { groupsSettingsParams } from 'settings/groups/settings';

import { IGroup } from 'store/groups';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { Show } from 'wui/Show';
import { Box } from 'wui/Box';
import { AspectRatio } from 'wui/AspectRatio';

import { Link } from 'common/components/Link';
import { GroupInfo } from 'common/components/GroupInfo';
import { GroupImage } from 'common/components/GroupImage';
import { RoleIcon } from 'common/components/RoleIcon';
import { UISref } from 'router/react';
import { useBiParams } from 'common/hooks/useBiParams';

import { GroupGridItemAction } from './GroupGridItemAction';

import styles from './GroupGridItem.scss';

interface IGroupGridItemProps {
  group: IGroup;
}

export function GroupGridItem({ group }: IGroupGridItemProps) {
  const { isMobile } = useEnvironment();
  const biParams = useBiParams();

  const settings = useSettings();

  const aspectRatio = settings.get(groupsSettingsParams.imageRatio);

  return (
    <Card
      gap="SP0"
      padding="SP0"
      sideBorders={!isMobile}
      className={styles.root}
    >
      <Show if={settings.get(groupsSettingsParams.showImage)}>
        <AspectRatio aspectRatio={aspectRatio}>
          <UISref
            state="group"
            params={{ slug: group.slug }}
            bi={groupsLivesiteClick({
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'click_on_group',
            })}
          >
            <a tabIndex={-1}>
              <GroupImage
                fluid
                resize="cover"
                image={group.coverImage?.image}
              />
            </a>
          </UISref>
        </AspectRatio>
      </Show>
      <CardContent disableSideGutters className={styles.content}>
        <Box verticalAlign="middle">
          <Link
            state="group"
            params={{ slug: group.slug }}
            className={cls(styles.title, { [styles.mobile]: isMobile })}
            bi={groupsLivesiteClick({
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'click_on_group',
            })}
          >
            {group.name}
          </Link>
          <Show if={settings.get(groupsSettingsParams.showAdminBadge)}>
            <RoleIcon role={group.role} />
          </Show>
        </Box>
        <GroupInfo wired misc groupId={group.id as string} />
      </CardContent>
      <Show if={settings.get(groupsListWidgetSettingsParams.showButton)}>
        <CardActions
          paddingTop="SP1"
          disableSideGutters
          className={styles.actions}
        >
          <GroupGridItemAction group={group} fullWidth={true} />
        </CardActions>
      </Show>
    </Card>
  );
}

GroupGridItem.displayName = 'GroupGridItem';
