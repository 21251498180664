import React from 'react';
import cls from 'classnames';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { groupsSelectGroupFromList } from '@wix/bi-logger-groups/v2';

import { groupsSettingsParams as settingsParams } from 'settings/groups/settings';

import type { IGroup } from 'store/groups';

import { Box } from 'wui/Box';
import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';
import { TextButton } from 'wui/TextButton';
import { ListItemIcon } from 'wui/ListItemIcon';

import { UISref } from 'router/react';
import { GroupInfo } from 'common/components/GroupInfo';
import { RoleIcon } from 'common/components/RoleIcon';
import { GroupImage } from 'common/components/GroupImage';

import { ImageRatio } from 'settings/consts';

import styles from './GroupListItem.scss';

interface IGroupListItemProps {
  wired?: boolean;
  group: IGroup;
}

export function JoinedGroupListItem(props: IGroupListItemProps) {
  const { group, wired } = props;

  const settings = useSettings();
  const { isMobile } = useEnvironment();

  return (
    <ListItem disablePadding className={cls({ [styles.wired]: wired })}>
      <Show if={settings.get(settingsParams.showImage)}>
        <ListItemIcon>
          <Box width={40} height={40}>
            <GroupImage
              width={40}
              height={40}
              resize="cover"
              image={group.coverImage?.image}
              aspectRatio={ImageRatio.square}
            />
          </Box>
        </ListItemIcon>
      </Show>
      <ListItemText
        titleProps={{
          className: cls(styles.title, { [styles.mobile]: isMobile }),
        }}
        title={
          <Box verticalAlign="middle">
            <Typography variant="p2-16" noWrap>
              <UISref
                state="group"
                params={{ slug: group.slug }}
                bi={groupsSelectGroupFromList({
                  groupId: group.id as string,
                  origin: 'new_layout_groups_sidebar_my_groups',
                })}
              >
                <TextButton as="a" variant="secondary">
                  {group.name}
                </TextButton>
              </UISref>
            </Typography>
            <Show if={settings.get(settingsParams.showAdminBadge)}>
              <RoleIcon role={group.role} />
            </Show>
          </Box>
        }
        subtitle={<GroupInfo misc wired={wired} groupId={group.id as string} />}
      />
    </ListItem>
  );
}

JoinedGroupListItem.displayName = 'JoinedGroupListItem';
